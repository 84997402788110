export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Whitelabel',
    icon: 'LayersIcon',
    children: [
      {
        title: 'List',
        route: 'whitelabel-list',
      },
      {
        title: 'Add',
        route: 'whitelabel-add',
      },
    ],
  },
  {
    title: 'User Admin',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List',
        route: 'users-users-list',
      },
      {
        title: 'Add',
        route: 'user-add',
      },
    ],
  },
  {
    title: 'Customer Admin',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List',
        route: 'customers-customers-list',
      },
      {
        title: 'Add',
        route: 'customer-add',
      },
    ],
  },
  {
    title: 'Banner',
    icon: 'AlertCircleIcon',
    children: [
      {
        title: 'Themes',
        route: 'theme-list',
      },
      {
        title: 'Locations',
        route: 'location-list',
      },
      {
        title: 'Magazine Media',
        route: 'magazine-media',
      },

      {
        title: 'Offers',
        route: 'offer-list',
      },

      {
        title: 'Trending Now',
        route: 'trending-now',
      },
    ],
  },
  {
    title: 'Booking',
    icon: 'ListIcon',
    children: [
      {
        title: 'List',
        route: 'booking-list',
      },
    ],
  },
  {
    title: 'Hotel Content',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'List',
        route: 'hotel-content-list',
      },
      {
        title: 'Disabled Hotels',
        route: 'disabled-hotel-list',
      },
    ],
  },
  {
    title: 'Content-Admin',
    icon: 'EditIcon',
    children: [
      {
        title: 'Static content',
        route: 'static-content',
      },
      {
        title: 'Remarks',
        route: 'remarks',
      },
      {
        title: 'Fraud Check',
        route: 'fraud-check',
      },
      {
        title: 'Promotion Banner',
        route: 'promotion-banner-list',
      },
      {
        title: 'Coupon Code',
        route: 'coupon-code',
      },
      {
        title: 'Airline Baggage',
        route: 'airline-baggage-list',
      },
      {
        title: 'Page Meta',
        route: 'page-meta',
      },
    ],
  },
  {
    title: 'Translations',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Resort Translation',
        route: 'resort-translation',
      },
      {
        title: 'Keyword Translation',
        route: 'keyword-translation',
      },
      {
        title: 'Error Translation',
        route: 'error-translation',
      },
      {
        title: 'Local Tax Translation',
        route: 'local-tax',
      },
    ],
  },
  {
    title: 'Saved Trips',
    icon: 'SaveIcon',
    children: [
      {
        title: 'List',
        route: 'saved-trips',
      },
    ],
  },
  {
    title: 'User-Rights',
    route: 'user-rights',
    icon: 'LockIcon',
  },
  {
    title: 'Currency',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'List',
        route: 'currency-list',
      },
    ],
  },
  {
    title: 'Language',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'List',
        route: 'language-list',
      },
    ],
  },
  {
    title: 'FAQ',
    icon: 'AlertCircleIcon',
    children: [
      {
        title: 'List',
        route: 'faq-list',
      },

      {
        title: 'Add',
        route: 'faq-add',
      },
    ],
  },
  {
    title: 'Logs',
    route: 'logs',
    icon: 'ListIcon',
  },
  {
    title: 'Amenities',
    icon: 'EditIcon',
    children: [
      {
        title: 'List',
        route: 'amenities-list',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Date Settings',
        route: 'settings',
      },
      {
        title: 'Contact Settings',
        route: 'contact-settings',
      },
    ],
  },
];
