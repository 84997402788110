<template>
  <div class="navbar-container d-flex content align-items-center">
     <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-hide="resetStatus"
      @on-submit="switchLiveMode"
    />

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>


    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ auth.user.firstName }}
            </p>
            <span class="user-status">{{ role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="auth.user.profilePic"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <!-- <span>Profile</span> -->
          <router-link to="/profile">
            Profile
          </router-link>

        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BFormCheckbox, BAlert
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import axios from 'axios'
import { resolveUserRoleName } from '@/helpers/constant'
import ConfirmDisable from "@/views/confirm-disable/ConfirmDisable.vue"
import ModuleService from '@/services/module/module.service'
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { resConditionCheck} from "@/helpers/constant";
import DashboardService from '@/services/dashboard/dashboard.service';


export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormCheckbox,
    // Navbar Components
    DarkToggler,
    BAlert,
    ConfirmDisable,

  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      firstName: "",
      role: "",
      profilePic: "",
      switchBackToSuperAdmin: false,
      disableMsg: "",
      finalMode: "",
      status2: ""
    }
  },

  computed: {
    ...mapState(["auth"])
  },

  async mounted() {
    this.role = resolveUserRoleName(this.auth.user.roleId)
    await this.getModuleStatus()

  },

  methods: {
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('superAdmin_userId')
      localStorage.removeItem('signinAsAdmin')

      if(localStorage.getItem('signinWLAdmin')){
        axios.defaults.headers.wl_code = 1;
        localStorage.removeItem("signinWLAdmin");
        localStorage.removeItem("whitelabel_code");
      }

      this.$store.commit('auth/SET_USER', null);

      this.$store.commit('auth/SET_TOKEN', null);

      this.$router.replace({ name: 'login' })
    },

     async getModuleStatus() {
      try {
        const res = await ModuleService.getModuleListing()
        if(resConditionCheck(res.statusText) && res.data.data) {
          let modeArray = res.data.data.map(e => {return e.mode})
          this.finalMode = modeArray.every(e => e === true)
        }
      } catch (error) {
        console.log("error", error)
        const errorData = errorResponseHandler(error)
         this.$toast({
            component: ToastificationContent,
            props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
            }
          });
      }
    },


      switchLiveMode(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModal.loading = true;
        DashboardService.activateDeactivateLiveMode()
          .then(res => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            if (resConditionCheck(res.statusText)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.finalMode = !this.finalMode
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
    },

      resetStatus() {
    this.finalMode = !this.finalMode
    },


  }
}
</script>

<style>
.top-live-status {
      display: flex;
    /* width: 50%; */
    /* justify-content: space-between; */
    align-items: center;
}
/* .vertical-layout .header-navbar.floating-nav {z-index: 9999;} */
</style>
