import axios from 'axios';

function getDashboardData(payload, wl_code) {
    let wlCode = axios.defaults.headers.wl_code
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
    const response = axios.get('dashboard/get-statics', {
        params:payload
    })
    axios.defaults.headers.wl_code = wlCode
    return response
}

function activateDeactivateLiveMode() {
    return axios.post('module/activate-deactivate-live')
}

export default {
    getDashboardData,
    activateDeactivateLiveMode
}
